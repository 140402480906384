import { defineStore } from "pinia";
import { computed, ref } from "vue";

export const useGameStore = defineStore(
  "gameList",
  () => {
    const gameList = ref([]);
    const downStore = ref([]);
    return {
      gameList,
      downStore,
    };
  },
  {
    // 持久化存储
    persist: true,
  }
);
